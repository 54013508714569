import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const withAuth = WrappedComponent => (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [authUser, setAuthUser] = useState({});

    useEffect(() => {
        async function auth() {
            try {
                const result = await axios('/api/users/auth/verify');
                setIsAuthenticated(result.status < 400);
                setAuthUser(result.data);
            } catch {
                setIsAuthenticated(false);
            }
            setIsLoading(false);
        }
        auth();
    }, [setIsAuthenticated, setIsLoading]);

    if (isLoading) {
        return <></>;
    }

    return (
        isAuthenticated ? <WrappedComponent {...props} authUser={authUser} /> : <Navigate to='/login' />
    );
}

export default withAuth;