import { Box, Container, Divider, Typography } from '@mui/material';
import React from 'react';

const About = () => {
    return (
        <Box sx={{ ml: { 'xs': 5, 'sm': 5, 'md': 10}, mr: { 'xs': 5, 'sm': 20, 'md': 40}, maxWidth: '80%' }}>
                    <Typography
                        component="h1"
                        variant="h3"
                        color="#eee"
                        sx={{ fontWeight: 700}}
                    >
                        about
                    </Typography>
                    <Typography
                        component="h2"
                        variant="h5"
                        color="#eee"
                        sx={{ fontWeight: 500, mt: 5 }}
                    >
                        what is dsnmakr?
                    </Typography>
                    <Divider sx={{ width: '50px', borderColor: '#eee', borderWidth: '5px' }}/>
                    <Typography
                        component="p"
                        color="#eee"
                        sx={{ fontWeight: 300, mt: 1 }}
                    >
                        &bull; a web app designed to help users automate decisions and fight decision fatigue.
                    </Typography>
                    <Typography
                        component="h2"
                        variant="h5"
                        color="#eee"
                        sx={{ fontWeight: 500, mt: 5 }}
                    >
                        what is decision fatigue?
                    </Typography>
                    <Divider sx={{ width: '50px', borderColor: '#eee', borderWidth: '5px' }}/>
                    <Typography
                        component="p"
                        color="#eee"
                        sx={{ fontWeight: 300, mt: 1 }}
                    >
                        &bull; decision fatigue, describes the impaired ability to make good decisions and control behavior as a result of making hundreds of repeated decisions per day
                    </Typography>
                    <Typography
                        component="p"
                        color="#eee"
                        sx={{ fontWeight: 300, mt: 1 }}
                    >
                        &bull; Decision fatigue can lead to poor sense of control, impulsive or irrational decisions, decreased productivity and an impaired sense of well-being.
                    </Typography>
                    <Typography
                        component="h2"
                        variant="h5"
                        color="#eee"
                        sx={{ fontWeight: 500, mt: 5 }}
                    >
                        why is dsnmakr useful?
                    </Typography>
                    <Divider sx={{ width: '50px', borderColor: '#eee', borderWidth: '5px' }}/>
                    <Typography
                        component="p"
                        color="#eee"
                        sx={{ fontWeight: 300, mt: 1 }}
                    >
                        &bull; <Typography component="span" sx={{color: '#FBB87C'}}>dsnmakr</Typography>, the first app of its kind, allows users to automate four major and common life decisions : what to do, where to go, what to eat, and what to wear.
                    </Typography>
                    <Typography
                        component="p"
                        color="#eee"
                        sx={{ fontWeight: 300, mt: 1 }}
                    >
                        &bull; Users input choices for all categories. The app will generate a random list of final decisions. Users can regenerate the decision list or specify “when” to do certain tasks using the “add when” feature.
                    </Typography>
        </Box>
    );
};

export default About;