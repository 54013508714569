import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation, useMatch, useParams } from 'react-router-dom';

import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';

import { SvgIcon } from '@mui/material';
import { ReactComponent as AboutIcon } from '../../svg/about.svg';
import { ReactComponent as PastDecisionsIcon } from '../../svg/past-decisions.svg';

const drawerWidth = 300;

const pages = [
    {
        name: 'signup',
        link: '/signup',
        icon: AppRegistrationOutlinedIcon
    },
    {
        name: 'login',
        link: '/login',
        icon: LoginOutlinedIcon
    },
    {
        name: 'about',
        link: '/about',
        icon: AboutIcon,
        customIcon: true
    },
    {
        name: 'creator/contact',
        link: '/contact',
        icon: AccountCircleOutlinedIcon

    }
];

export default function Header() {
    const { pathname } = useLocation();
    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: { 'xs': 60, 'md': drawerWidth },
                        boxSizing: 'border-box',
                        background: '#125587',
                        color: '#eee'
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar>
                    <Link to={'/'} style={{ textDecoration: 'none', color: '#eee' }}>
                        <Typography variant="h4" noWrap sx={{ fontWeight: 700, paddingLeft: '30px', paddingTop: '60px', display: { 'xs': 'none', 'md': 'block' } }}>
                            dsnmakr
                        </Typography>
                    </Link>
                </Toolbar>
                <List sx={{ marginTop: { 'xs': '-40px', 'sm': '20px' } }}>
                    {pages.map((page) => (
                        <ListItem key={page.name} disablePadding sx={{ paddingLeft: { 'xs': '0px', 'md': '35px' }, marginTop: '15px', background: pathname === page.link ? '#eee' : 'initial', color: pathname === page.link ? '#125587' : '#eee' }}>
                            <ListItemButton component={Link} to={page.link}>
                                <ListItemIcon sx={{ color: pathname === page.link ? '#125587' : '#eee' }}>
                                    {page.customIcon ? <SvgIcon sx={{ fill: pathname === page.link ? '#125587' : '#eee' }} component={page.icon} inheritViewBox /> : <page.icon />}
                                </ListItemIcon>
                                <ListItemText primary={page.name} sx={{ display: { 'xs': 'none', md: 'initial' } }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </Box>
    );
}
