import { Container, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import Header from './Header';

const PageWrapper = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', minHeight: '100vh' }}>
            <Header />
            <Toolbar>
                    <Link to={'/'} style={{textDecoration: 'none', color: '#eee'}}>
                        <Typography variant="h4" noWrap sx={{ fontWeight: 700, paddingLeft: '130px', paddingTop: '30px', display: { 'xs': 'initial', 'md': 'none' }}}>
                            dsnmakr
                        </Typography>
                    </Link>
                </Toolbar>
            <Container sx={{ marginLeft: { 'xs': '60px', 'sm': '30px', 'md': '300px'}, minWidth: { 'xs': 'calc(100vw - 60px);', 'sm': 'calc(100vw - 0px);', 'md': 'calc(100vw - 300px);'} }}>
                <Outlet />
            </Container>
        </Box>
    );
};

export default PageWrapper;