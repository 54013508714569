import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Alert, Box, Button, Container, FormLabel, TextField, Typography } from '@mui/material';
import axios from 'axios';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .required('You must enter an email address to reset your password.'),
});

const ResetPassword = () => {
    const [status, setStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const res = await axios.put('/api/users/reset-password', values);
                setStatus(res.status);
                setResponseMessage(res.data);
            } catch (error) {
                setStatus(error.response.status);
                setResponseMessage(error.response.data);
            }
        },
    });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', minHeight: '100vh', color: '#eee' }}>
            <Container maxWidth="xs">
                <Box sx={{ width: { xs: '80%', sm: '100%' } }}>
                    <Typography variant="h3" sx={{ fontWeight: 700, textAlign: 'center', m: 2 }}>reset password</Typography>
                    <Typography sx={{ textAlign: 'center', m: 2 }}>enter the email address associated with your account and we'll send you a password reset link.</Typography>
                    {status && <Alert severity={status < 400 ? 'success' : 'error'} sx={{ mt: 2, mb: 2 }}>
                        {responseMessage || 'An unknown error occured. Please try again.'}
                    </Alert>}
                    <form onSubmit={formik.handleSubmit}>
                        <FormLabel htmlFor="email" sx={{ color: '#eee' }}>email</FormLabel>
                        <TextField
                            fullWidth
                            sx={{ mb: 3, background: '#eee', borderRadius: '8px' }}
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ border: 'solid 1px #eee' }}>
                            reset password
                        </Button>
                    </form>
                </Box>
            </Container>
        </Box>
    );
};

export default ResetPassword;