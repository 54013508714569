import { Box, Container, Divider, Link, Typography } from '@mui/material';
import React from 'react';

const CreatorContact = () => {
    return (
        <Box sx={{ ml: { 'xs': 5, 'sm': 5, 'md': 10}, mr: { 'xs': 5, 'sm': 20, 'md': 40}, maxWidth: '80%' }}>
            <Typography
                component="h1"
                variant="h3"
                color="#eee"
                sx={{ fontWeight: 700 }}
            >
                creator/contact
            </Typography>
            <Typography
                component="h2"
                variant="h5"
                color="#eee"
                sx={{ fontWeight: 500, mt: 5 }}
            >
                who created dsnmakr?
            </Typography>
            <Divider sx={{ width: '50px', borderColor: '#eee', borderWidth: '5px' }} />
            <Typography
                component="p"
                color="#eee"
                sx={{ fontWeight: 300, mt: 2 }}
            >
                &bull; Yasmine Abbey (me!) a 4th year medical student who often struggled with making way too many decisions per day.
            </Typography>
            <Typography
                component="p"
                color="#eee"
                sx={{ fontWeight: 300, mt: 2 }}
            >
                &bull; Out of frustration, fatigue, and a desire for someone or something to tell her what to do, she created dsnmakr.
            </Typography>
            <Typography
                component="p"
                color="#eee"
                sx={{ fontWeight: 300, mt: 2 }}
            >
                &bull; She hopes this app optimizes your every-day decisions and gives you the space to focus on what matters
            </Typography>
            <Typography
                component="h2"
                variant="h5"
                color="#eee"
                sx={{ fontWeight: 500, mt: 5 }}
            >
                contact
            </Typography>
            <Divider sx={{ width: '50px', borderColor: '#eee', borderWidth: '5px' }} />
            <Typography
                component="p"
                color="#eee"
                sx={{ fontWeight: 300, mt: 2 }}
            >
                questions/concerns/ideas? contact me!
            </Typography>
            <Typography
                component="p"
                color="#eee"
                sx={{ fontWeight: 300, mt: 2 }}
            >
                email: <Link sx={{ color: '#eee' }} href="mailto:yasmineabbey@gmail.com" target="_blank">
                    yasmineabbey@gmail.com
                </Link>
            </Typography>
            <Typography
                component="p"
                color="#eee"
                sx={{ fontWeight: 300, mt: 2 }}
            >
                LinkedIn: <Link sx={{ color: '#eee' }} href="https://www.linkedin.com/in/yasmineabbey-503b3197/" target="_blank">
                    https://www.linkedin.com/in/yasmineabbey-503b3197/
                </Link>
            </Typography>
        </Box>
    );
};

export default CreatorContact;