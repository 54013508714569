import { Grid, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import { ReactComponent as CoffeeCup } from '../../svg/coffee-cup.svg';
import { useOutletContext } from 'react-router-dom';

const Coffee = () => {
    const authUser = useOutletContext();

    return (
        <Box>
            <Grid container spacing={3} sx={{ maxWidth: '80vw', margin: '0 auto', flexDirection: 'column' }}>
                <Grid item xs={12}>
                    <Typography component="p" variant="h5" sx={{ color: '#eee' }}>
                        hello {authUser.name},
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h1" variant="h3" sx={{ color: '#eee' }}>
                        coffee
                    </Typography>
                    <Typography component="h2" variant="h5" sx={{ color: '#eee', mt: 4 }}>
                        if you like the app, consider gifting me a cup of coffee
                    </Typography>
                </Grid>
                <Grid item xs={10} sm={10} md={8}>
                    <Box sx={{ width: '100%', backgroundColor: '#125587', ml: 3 }}>
                        <Grid container>
                            <Grid item xs={12} sm={3}>
                                <CoffeeCup style={{ height: '100%', transform: 'scale(1.115)', left: "-60px", top:"-8px", position: 'relative' }} />
                            </Grid>
                            <Grid item xs={12} sm={7} sx={{ ml: { xs: 6, sm: 8 }, mt: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography sx={{ color: '#eee', mb: 1 }}>Venmo: @yabbey</Typography>
                                <Typography sx={{ color: '#eee', mb: 1 }}>PayPal: <Link href="paypal.me/dsnmakr" target="_blank" sx={{ color: '#eee', display: 'inline' }}>paypal.me/dsnmakr</Link></Typography>
                                <Typography sx={{ color: '#eee', mb: 1 }}>CashApp: $Yabbey20</Typography>
                                <Typography sx={{ color: '#eee', mb: 1, wordWrap: 'break-word', mr: {xs: 10, sm: 0 } }}>ETH: 0x488714189988eAfbd65177a38309768f1Ff9C462</Typography>
                                <Typography sx={{ color: '#eee', mb: 1, wordWrap: 'break-word', mr: {xs: 10, sm: 0 } }}>BTC: 38WoyerLRgsVkUjhUTtEikX8d8uuTfL9dZ</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    )
};

export default Coffee;