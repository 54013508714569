import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  categories: [],
  status: 'idle',
  error: null
};

export const fetchMyCategories = createAsyncThunk('categories/getMyCategories', async () => {
  const response = await axios.get('/api/categories/my')
  return response.data
})

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addNewCategory(state, action) {
      state.categories.push(action.payload);
    },
    clearCategories(state, action) {
      state.categories = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMyCategories.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchMyCategories.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.categories = action.payload
      })
      .addCase(fetchMyCategories.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

export const { addNewCategory, clearCategories } = categoriesSlice.actions

export default categoriesSlice.reducer

export const selectCategories = (state) =>
  state.categories;