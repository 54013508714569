import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Alert, Box, Button, Container, FormLabel, Paper, TextField, Typography } from '@mui/material';
import axios from 'axios';

const validationSchema = yup.object({
    username: yup
        .string('Enter your username')
        .required('You must enter a username to sign up.'),
    email: yup
        .string('Enter your email')
        .required('You must enter an email to sign up.'),
    name: yup
        .string('Enter your name')
        .required('You must enter a full name to sign up.'),
    password: yup
        .string('Enter your password')
        .required('You must enter a password to sign up.'),
});

const Register = () => {
    const [status, setStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const formik = useFormik({
        initialValues: {
            username: '',
            email: '',
            password: '',
            name: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const res = await axios.post('/api/users/register', values);
                setStatus(res.status);
                setResponseMessage(res.data);
            } catch (error) {
                setStatus(error.response.status);
                setResponseMessage(error.response.data);
            }
        },
    });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', minHeight: '100vh', color: '#eee' }}>
            <Container maxWidth="xs">
                <Box sx={{ width: '80%' }}>
                    <Typography variant="h3" sx={{ fontWeight: 700, textAlign: 'center', m: 2 }}>sign up</Typography>
                    {status && <Alert severity={status < 400 ? 'success' : 'error'} sx={{ mt: 2, mb: 2 }}>
                        {responseMessage || 'An unknown error occured. Please try again.'}
                    </Alert>}
                    <form onSubmit={formik.handleSubmit}>
                        <FormLabel htmlFor="username" sx={{ color: '#eee' }}>username</FormLabel>
                        <TextField
                            fullWidth
                            sx={{ mb: 3, background: '#eee', borderRadius: '8px' }}
                            id="username"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}
                        />
                        <FormLabel htmlFor="email" sx={{ color: '#eee' }}>email</FormLabel>
                        <TextField
                            fullWidth
                            sx={{ mb: 3, background: '#eee', borderRadius: '8px' }}
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <FormLabel htmlFor="name" sx={{ color: '#eee' }}>full name</FormLabel>
                        <TextField
                            fullWidth
                            sx={{ mb: 3, background: '#eee', borderRadius: '8px' }}
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <FormLabel htmlFor="password" sx={{ color: '#eee' }}>password</FormLabel>
                        <TextField
                            fullWidth
                            sx={{ mb: 3, background: '#eee', borderRadius: '8px' }}
                            id="password"
                            name="password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ border: 'solid 1px #eee' }}>
                            sign up
                        </Button>
                    </form>
                </Box>
            </Container>
        </Box>
    );
};

export default Register;