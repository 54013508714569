import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import _ from 'lodash';

const QuickDecision = () => {
    const authUser = useOutletContext();

    const [quickDecision, setQuickDecision] = useState(null);

    useEffect(() => {
        const quickDecide = () => {
            const options = ['yes', 'no'];
            const optionIndex = Math.floor(Math.random() * options.length);
            setQuickDecision(options[optionIndex]);
        }
        if (!quickDecision) quickDecide();
    }, [quickDecision, setQuickDecision]);

    if (!quickDecision) return null;

    return (
        <Box>
            <Grid container spacing={3} sx={{ maxWidth: '80vw', margin: '0 auto', flexDirection: 'column' }}>
                <Grid item xs={12}>
                    <Typography component="p" variant="h5" sx={{ color: '#eee' }}>
                        hello {authUser.name},
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h1" variant="h3" sx={{ color: '#eee' }}>
                        quick decide
                    </Typography>
                    <Typography component="h2" variant="h5" sx={{ color: '#eee', mt: 4 }}>
                        yes or no
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', color: '#eee', backgroundColor: quickDecision === 'yes' ? '#418148' : '#EB5757', width: '150px', height: '50px', border: 'solid 1px #eee;', borderRadius: '5px' }}>
                        <Typography align="center">{quickDecision}</Typography>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    );
};

export default QuickDecision;