import _ from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AuthenticatedHeader from '../navigation/AuthenticatedHeader';
import { useDispatch, useSelector } from 'react-redux';
import { clearCategories, fetchMyCategories, selectCategories } from '../../slices/categoriesSlice';
import { Add } from '@mui/icons-material';
import { addNewItem, clearItems, deleteItem, fetchMyItems, selectItems } from '../../slices/itemsSlice';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PlaylistRemoveOutlinedIcon from '@mui/icons-material/PlaylistRemoveOutlined';

const Dashboard = ({ authUser }) => {
    const dispatch = useDispatch();
    const { categories, status: categoriesStatus } = useSelector(selectCategories);
    const { items, status: itemsStatus } = useSelector(selectItems);

    useEffect(() => {
        const loadCategories = () => {
            if (categoriesStatus === 'idle') {
                dispatch(fetchMyCategories())
            }
        }
        loadCategories();
        return () => dispatch(clearCategories())
    }, []);

    useEffect(() => {
        const loadItems = () => {
            if (itemsStatus === 'idle') {
                dispatch(fetchMyItems())
            }
        }
        loadItems();
        return () => dispatch(clearItems())
    }, []);

    const [currentCategory, setCurrentCategory] = useState({});
    const [addItemOpen, setAddItemOpen] = useState(false);
    const [itemToAdd, setItemToAdd] = useState("");

    const addItem = async (e) => { 
        e.preventDefault();
        try {
            const res = await axios.post(`/api/items/new`, { name: itemToAdd, CategoryId: currentCategory.id });
            if (res.status === 201) {
                dispatch(addNewItem(res.data));
            }
        } catch (error) {
            console.log(error);
        }
        setAddItemOpen(false); 
        setCurrentCategory({}); 
        setItemToAdd(""); 
    };

    const makeDecision = async () => {
        await axios.post('/api/decisions/new');
        const recentDecision = await axios.get('/api/decisions/recent');
        setDecision(recentDecision.data);
        setIsDeciding(true);
    };

    const saveDecision = async () => {
        await axios.put('/api/decisions/accept');
        setIsDeciding(false);
        setDecision({});
        setAddingWhen(false);
        setWhenDecided(false);
        setChecked([]);
    };

    const [isDeciding, setIsDeciding] = useState(false);
    const [decision, setDecision] = useState({});

    const [isAddingWhen, setAddingWhen] = useState(false);
    const [whenDecided, setWhenDecided] = useState(false);

    const [checked, setChecked] = React.useState([]);

    const handleCheckItem = (id) => () => {
        const currentIndex = checked.indexOf(id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    if (isDeciding && decision) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', minHeight: '100vh' }}>
                <AuthenticatedHeader />
                <Toolbar>
                    <Link to={'/dashboard'} style={{ textDecoration: 'none', color: '#eee' }}>
                        <Typography variant="h4" noWrap sx={{ fontWeight: 700, paddingLeft: '130px', paddingTop: '30px', display: { 'xs': 'initial', 'md': 'none' } }}>
                            dsnmakr
                        </Typography>
                    </Link>
                </Toolbar>
                <Container sx={{ marginLeft: { 'xs': '15px', 'sm': '30px', 'md': '300px' }, minWidth: { 'xs': 'calc(100vw - 60px);', 'sm': 'calc(100vw - 30px);', 'md': 'calc(100vw - 300px);' }, minHeight: '100vh' }}>
                    <Grid container spacing={3} sx={{ maxWidth: '80vw', margin: '0 auto', flexDirection: 'column' }}>
                        <Grid item xs={12}>
                            <Typography component="p" variant="h5" sx={{ color: '#eee' }}>
                                hello {authUser.name},
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h3" sx={{ color: '#eee' }}>
                                {isAddingWhen ? whenDecided ? "decide when" : "add when" : "decide"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {isAddingWhen && !whenDecided && <Typography sx={{ paddingTop: '15px', mb: 4, color: '#eee' }}>choose up to three tasks to specify <b>when</b> to complete.</Typography>}
                            <TableContainer>
                                <Table aria-label="decision table" sx={{ borderCollapse: 'separate', borderSpacing: '15px' }}>
                                    <TableBody sx={{ border: 'none', background: 'none' }}>
                                        {_.map(decision.DecisionItems, (item) => {
                                            if (whenDecided && item.time === null) return null;
                                            return (
                                                <TableRow
                                                    key={item.id}
                                                    sx={{ border: 'none', background: 'none' }}
                                                >
                                                    {isAddingWhen ?
                                                        <>
                                                            {!whenDecided && <TableCell sx={{ border: 'none', width: '10%' }} align="right">
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={checked.indexOf(item.id) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    onClick={handleCheckItem(item.id)}
                                                                    disabled={(checked.indexOf(item.id) === -1 && checked.length >= 3)}
                                                                    sx={{ transform: 'scale(2)', '& .MuiSvgIcon-root': { fill: '#eee' }, '& .Mui-checked': { color: '#222' } }}
                                                                />
                                                            </TableCell>}
                                                            <TableCell sx={{ width: whenDecided ? '40%' : '50%', background: '#222222', color: '#eee', textAlign: 'center', padding: '20px', border: 'none', borderRadius: '5px' }}>{item.itemName.toUpperCase()}</TableCell>
                                                            <TableCell sx={{ border: 'none', width: whenDecided ? '20%' : '30%', color: '#eee', textAlign: 'center' }}>{whenDecided && 'in the'}</TableCell>
                                                            {whenDecided && item.time &&
                                                                <TableCell
                                                                    sx={{ width: '40%', background: '#222222', color: '#eee', textAlign: 'center', padding: '20px', border: 'none', borderRadius: '5px' }}>
                                                                    {item.time && item.time.toUpperCase()}
                                                                </TableCell>
                                                            }
                                                        </> :
                                                        <>
                                                            <TableCell component="th" scope="row" sx={{ textAlign: 'center', background: '#eee', width: '60%', padding: '20px', border: 'none', borderRadius: '5px' }}>
                                                                {item.categoryName}
                                                            </TableCell>
                                                            <TableCell sx={{ background: '#222222', color: '#eee', textAlign: 'center', padding: '20px', border: 'none', borderRadius: '5px' }}>{item.itemName.toUpperCase()}</TableCell>
                                                        </>
                                                    }
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {isAddingWhen ?
                                <Box>
                                    {whenDecided && <Button
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                        sx={{ border: 'solid 1px #eee', mb: 2 }}
                                        onClick={saveDecision}>
                                        save decision
                                    </Button>}
                                    <Button
                                        fullWidth
                                        color={!whenDecided ? "primary" : ""}
                                        variant={!whenDecided ? "contained" : ""}
                                        sx={{ color: '#eee', border: whenDecided ? 'none' : 'solid 1px #eee' }}
                                        onClick={async () => {
                                            const updatedDecision = await axios.put(`/api/decisions/${decision.id}/when`, {
                                                ids: checked
                                            });
                                            setDecision(updatedDecision.data)
                                            setWhenDecided(true);
                                        }}>decide when{whenDecided && ' again'}</Button>
                                </Box> :
                                <Box>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                        sx={{ border: 'solid 1px #eee', mb: 2 }}
                                        onClick={saveDecision}>
                                        save decision
                                    </Button>
                                    <Button
                                        fullWidth
                                        sx={{ color: '#eee', border: 'solid 1px #eee', mb: 2 }}
                                        onClick={async () => {
                                            await axios.delete(`/api/decisions/${decision.id}/delete`);
                                            setIsDeciding(false);
                                            setDecision({});
                                        }}>decide again</Button>
                                    <Button
                                        fullWidth
                                        sx={{ color: '#eee', border: 'solid 1px #eee' }}
                                        onClick={() => {
                                            setAddingWhen(true);
                                        }}>add when</Button>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        )
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', minHeight: '100vh' }}>
            <AuthenticatedHeader />
            <Toolbar>
                <Link to={'/dashboard'} style={{ textDecoration: 'none', color: '#eee' }}>
                    <Typography variant="h4" noWrap sx={{ fontWeight: 700, paddingLeft: '130px', paddingTop: '30px', display: { 'xs': 'initial', 'md': 'none' } }}>
                        dsnmakr
                    </Typography>
                </Link>
            </Toolbar>
            <Container sx={{ marginLeft: { 'xs': '15px', 'sm': '30px', 'md': '300px' }, minWidth: { 'xs': 'calc(100vw - 60px);', 'sm': 'calc(100vw - 30px);', 'md': 'calc(100vw - 300px);' }, minHeight: '100vh' }}>
                <Grid container spacing={3} sx={{ maxWidth: '80vw', margin: '0 auto', flexDirection: 'column' }}>
                    <Grid item xs={12}>
                        <Typography component="p" variant="h5" sx={{ color: '#eee' }}>
                            hello {authUser.name},
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h3" sx={{ color: '#eee' }}>
                            home
                        </Typography>
                    </Grid>
                    {_.map(categories, category => {
                        const itemsInCategory = _.filter(items, item => item.CategoryId === category.id);
                        return (<Grid item xs={12} md={5} sx={{ textAlign: 'center' }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>{category.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ minWidth: '100%', p: 0 }}>
                                    <List sx={{ minWidth: '100%', p: 0 }}>
                                        {
                                            _.map(
                                                itemsInCategory,
                                                (item, index) =>
                                                    <ListItem secondaryAction={
                                                        <IconButton edge="end" aria-label="delete" onClick={async () => {
                                                            await axios.delete(`/api/items/${item.id}/delete`);
                                                            dispatch(deleteItem(item.id));
                                                        }}>
                                                            <PlaylistRemoveOutlinedIcon />
                                                        </IconButton>
                                                    } sx={{
                                                        backgroundColor: index % 2 != 0 ? '#D9D9D9' : '#F0F0F0',
                                                        "& .MuiListItemSecondaryAction-root": {
                                                            display: "none"
                                                        },
                                                        "&:hover": {
                                                            "& .MuiListItemSecondaryAction-root": {
                                                                display: "block"
                                                            }
                                                        }
                                                    }}>
                                                        <ListItemText sx={{ pl: 1 }}>{item.name}</ListItemText>
                                                    </ListItem>
                                            )
                                        }
                                        {addItemOpen && currentCategory.id === category.id ? 
                                        <ListItem sx={{ minWidth: '100%', backgroundColor: itemsInCategory.length % 2 != 0 ? '#D9D9D9' : '#F0F0F0' }}>
                                            <ListItemIcon sx={{ color: '#222' }}>
                                                <Add />
                                            </ListItemIcon>
                                            <form onSubmit={addItem}>
                                                <TextField fullWidth autoFocus onChange={(e) => setItemToAdd(e.target.value)} value={itemToAdd} />
                                                <Button type="submit" sx={{ display: 'none' }} />
                                            </form>
                                        </ListItem> :
                                        <Button onClick={() => {
                                            setCurrentCategory(category);
                                            setAddItemOpen(true);
                                        }} sx={{ minWidth: '100%', p: 0 }}>
                                            <ListItem sx={{ minWidth: '100%', backgroundColor: itemsInCategory.length % 2 != 0 ? '#D9D9D9' : '#F0F0F0' }}>
                                                <ListItemIcon sx={{ color: '#222' }}>
                                                    <Add />
                                                </ListItemIcon>
                                            </ListItem>
                                        </Button>}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>);
                    })}
                    <Grid item xs={12}>
                        <Box>
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{ border: 'solid 1px #eee' }}
                                onClick={makeDecision}
                                disabled={items.length <= 0}>
                                decide
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Dashboard;