import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { clearDecisions, fetchMyDecisions, selectDecisions } from '../../slices/decisionsSlice';

const PastDecisions = () => {

    const authUser = useOutletContext();

    const dispatch = useDispatch();
    const { decisions: pastDecisions, status: decisionsStatus } = useSelector(selectDecisions);

    useEffect(() => {
        const loadDecisions = () => {
            if (decisionsStatus === 'idle') {
                dispatch(fetchMyDecisions())
            }
        }
        loadDecisions();
        return () => dispatch(clearDecisions())
    }, []);

    return (
        <Box>
            <Grid container spacing={3} sx={{ maxWidth: '80vw', margin: '0 auto', flexDirection: 'column' }}>
                <Grid item xs={12}>
                    <Typography component="p" variant="h5" sx={{ color: '#eee' }}>
                        hello {authUser.name},
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h1" variant="h3" sx={{ color: '#eee' }}>
                        past decisions
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TableContainer component={Paper}>
                        <Table aria-label="past decisions table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ padding: '20px', paddingLeft: '35px', border: 'none' }}>date</TableCell>
                                    <TableCell sx={{ padding: '20px', paddingRight: '20px', border: 'none' }}>decision</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(pastDecisions, (decision, index) => (
                                    <TableRow
                                        key={decision.id}
                                        sx={{ border: 'none', backgroundColor: index % 2 != 0 ? '#D9D9D9' : '#F0F0F0' }}
                                    >
                                        <TableCell component="th" scope="row" sx={{ padding: '20px', paddingLeft: '35px', border: 'none' }}>
                                            {moment(decision.createdAt).format('M/D/Y')}
                                        </TableCell>
                                        <TableCell sx={{ padding: '20px', paddingRight: '20px', border: 'none' }}>{_.map(decision.DecisionItems, d => d.time != null ? `${d.itemName} in the ${d.time}` : d.itemName).join(', ')}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PastDecisions;