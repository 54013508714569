import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Alert, Box, Button, Container, FormLabel, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const validationSchema = yup.object({
    password: yup
        .string('Enter your new password')
        .required('You must enter a new password.'),
});

const UpdatePassword = () => {
    const { token: resetToken } = useParams();
    const [status, setStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const res = await axios.put('/api/users/update-password', {...values, resetToken});
                setStatus(res.status);
                setResponseMessage(res.data);
            } catch (error) {
                setStatus(error.response.status);
                setResponseMessage(error.response.data);
            }
        },
    });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', minHeight: '100vh', color: '#eee' }}>
            <Container maxWidth="xs">
                <Box sx={{ width: { xs: '80%', sm: '100%' } }}>
                    <Typography variant="h3" sx={{ fontWeight: 700, textAlign: 'center', m: 2 }}>update password</Typography>
                    <Typography sx={{ textAlign: 'center', m: 2 }}>enter a new password for your account.</Typography>
                    {status && <Alert severity={status < 400 ? 'success' : 'error'} sx={{ mt: 2, mb: 2 }}>
                        {responseMessage || 'An unknown error occured. Please try again.'}
                    </Alert>}
                    <form onSubmit={formik.handleSubmit}>
                        <FormLabel htmlFor="password" sx={{ color: '#eee' }}>new password</FormLabel>
                        <TextField
                            fullWidth
                            sx={{ mb: 3, background: '#eee', borderRadius: '8px' }}
                            id="password"
                            name="password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ border: 'solid 1px #eee' }}>
                            update password
                        </Button>
                    </form>
                </Box>
            </Container>
        </Box>
    );
};

export default UpdatePassword;