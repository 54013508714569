import { Box, Container, Typography } from '@mui/material';
import React from 'react';

const Home = () => {
    return (
        <Box sx={{ ml: { 'xs': 7, 'sm': 19, 'md': 'initial' }, mt: { 'xs': 15, 'md': 20 }, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100%' }}>
                    <Typography
                        component="h1"
                        variant="h3"
                        color="#eee"
                        align="center"
                        sx={{ fontWeight: 700, maxWidth: { 'xs': '250px', 'sm': '500px', 'md': 'initial'} }}
                    >
                        automate your decisions.
                    </Typography>
        </Box>
    );
};

export default Home;