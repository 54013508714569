import { Container, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import AuthenticatedHeader from './AuthenticatedHeader';

const AuthPageWrapper = ({authUser}) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', minHeight: '100vh' }}>
            <AuthenticatedHeader />
            <Toolbar>
                <Link to={'/'} style={{ textDecoration: 'none', color: '#eee' }}>
                    <Typography variant="h4" noWrap sx={{ fontWeight: 700, paddingLeft: '130px', paddingTop: '30px', display: { 'xs': 'initial', 'md': 'none' } }}>
                        dsnmakr
                    </Typography>
                </Link>
            </Toolbar>
            <Container sx={{ marginLeft: { 'xs': '15px', 'sm': '30px', 'md': '300px' }, minWidth: { 'xs': 'calc(100vw - 60px);', 'sm': 'calc(100vw - 30px);', 'md': 'calc(100vw - 300px);' }, minHeight: '100vh' }}>
                <Outlet context={authUser} />
            </Container>
        </Box>
    );
};

export default AuthPageWrapper;