import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Alert, Box, Button, Container, FormLabel, Link, TextField, Typography } from '@mui/material';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import axios from 'axios';

const validationSchema = yup.object({
    username: yup
        .string('Enter your username')
        .required('You must enter a username to log in.'),
    password: yup
        .string('Enter your password')
        .required('You must enter a password to log in.'),
});

const Login = () => {
    const [status, setStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [redirect, setRedirect] = useState(false);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const res = await axios.post('/api/users/login', values);
                if (res.status === 200) {
                    setRedirect(true);
                } else {
                    setStatus(res.status);
                    setResponseMessage(res.data);
                }
            } catch (error) {
                setStatus(error.response.status);
                setResponseMessage(error.response.data);
            }
        },
    });

    if (redirect) return <Navigate to='/dashboard' />;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', minHeight: '100vh', color: '#eee' }}>
            <Container maxWidth="xs">
                <Box sx={{ width: '80%' }}>
                    <Typography variant="h3" sx={{ fontWeight: 700, textAlign: 'center', m: 4 }}>log in</Typography>
                    {status && <Alert severity={status < 400 ? 'success' : 'error'} sx={{ mt: 2, mb: 2 }}>
                        {responseMessage || 'An unknown error occured. Please try again.'}
                    </Alert>}
                    <form onSubmit={formik.handleSubmit}>
                        <FormLabel htmlFor="username" sx={{ color: '#eee' }}>username</FormLabel>
                        <TextField
                            fullWidth
                            sx={{ mb: 3, background: '#eee', borderRadius: '8px' }}
                            id="username"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}
                        />
                        <FormLabel htmlFor="password" sx={{ color: '#eee' }}>password</FormLabel>
                        <TextField
                            fullWidth
                            sx={{ mb: 3, background: '#eee', borderRadius: '8px' }}
                            id="password"
                            name="password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ border: 'solid 1px #eee' }}>
                            log in
                        </Button>
                    </form>
                    <Link component={RouterLink} to='/reset-password' sx={{ color: '#eee', display: 'block', mt: 3, textAlign: 'center' }}>reset password?</Link>
                </Box>
            </Container>
        </Box>
    );
};

export default Login;