import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';

const initialState = {
  items: [],
  status: 'idle',
  error: null
};

export const fetchMyItems = createAsyncThunk('items/getMyItems', async () => {
  const response = await axios.get('/api/items/my')
  return response.data
})

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    addNewItem(state, action) {
      state.items.push(action.payload);
    },
    deleteItem(state, action) {
      state.items = _.filter(
        state.items, 
        i => parseInt(i.id) !== parseInt(action.payload)
      )
    },
    clearItems(state, action) {
      state.items = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMyItems.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchMyItems.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.items = action.payload
      })
      .addCase(fetchMyItems.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

export const { addNewItem, clearItems, deleteItem } = itemsSlice.actions

export default itemsSlice.reducer

export const selectItems = (state) =>
  state.items;