import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';

const initialState = {
  decisions: [],
  status: 'idle',
  error: null
};

export const fetchMyDecisions = createAsyncThunk('decisions/getMyDecisions', async () => {
  const response = await axios.get('/api/decisions/my')
  return response.data
})

const decisionsSlice = createSlice({
  name: 'decisions',
  initialState,
  reducers: {
    addNewDecision(state, action) {
      state.decisions.push(action.payload);
    },
    clearDecisions(state, action) {
      state.decisions = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMyDecisions.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchMyDecisions.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.decisions = action.payload
      })
      .addCase(fetchMyDecisions.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

export const { addNewDecision, clearDecisions } = decisionsSlice.actions

export default decisionsSlice.reducer

export const selectDecisions = (state) =>
  state.decisions;