import { configureStore } from '@reduxjs/toolkit';
import categoriesSlice from './slices/categoriesSlice';
import decisionsSlice from './slices/decisionsSlice';
import itemsSlice from './slices/itemsSlice';

export default configureStore({
    reducer: {
        categories: categoriesSlice,
        items: itemsSlice,
        decisions: decisionsSlice
    },
})