import { createTheme, responsiveFontSizes, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import PageWrapper from './components/navigation/PageWrapper';
import Home from './components/pages/Home';
import About from './components/pages/About';
import CreatorContact from './components/pages/CreatorContact';
import Login from './components/pages/Login';
import Register from './components/pages/Register';

import withAuth from './components/auth/withAuth';
import Dashboard from './components/pages/Dashboard';

import store from './store';

import SFProDisplay from './fonts/sf-pro-display.woff';
import Coffee from './components/pages/Coffee';

import "./App.css";
import AuthPageWrapper from './components/navigation/AuthPageWrapper';
import PastDecisions from './components/pages/PastDecisions';
import QuickDecision from './components/pages/QuickDecision';
import ResetPassword from './components/pages/ResetPassword';
import UpdatePassword from './components/pages/UpdatePassword';

const theme = responsiveFontSizes(createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#203864',
    },
    primary: {
      main: "#125587",
      contrastText: "#eee" //button text white instead of black
    },
  },
  spacing: 8,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'sfprodisplay';
        src: url(${SFProDisplay}) format('woff');
        font-weight: normal;
        font-style: normal;
      }
      `,
    },
  },
  typography: {
    fontFamily: [
      'sfprodisplay',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
}));

const DashboardWithAuth = withAuth(Dashboard);
const AuthenticatedPageWrapper = withAuth(AuthPageWrapper);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<PageWrapper />}>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/contact' element={<CreatorContact />} />
              <Route path='/login' element={<Login />} />
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path='/update-password/:token' element={<UpdatePassword />} />
              <Route path='/signup' element={<Register />} />
            </Route>
            <Route exact path='/dashboard' element={<DashboardWithAuth />} />
            <Route path='/dashboard' element={<AuthenticatedPageWrapper />}>
              <Route path='/dashboard/coffee' element={<Coffee />} />
              <Route path='/dashboard/about' element={<About />} />
              <Route path='/dashboard/contact' element={<CreatorContact />} />
              <Route path='/dashboard/quick-decision' element={<QuickDecision />} />
              <Route path='/dashboard/past-decisions' element={<PastDecisions />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;